import { useMemo } from 'react'
import { useSession as useNextAuthSession } from 'next-auth/react'

import { Session } from '@/types/auth'

export const useSession = () => {
  const { data, status } = useNextAuthSession()

  const isAuth = useMemo(() => status === 'authenticated', [status])

  // 초기 데이터를 지정하여 status가 변경되기 전의 상태를 제어
  if (status === 'loading') {
    return {
      data: null,
      status: 'loading',
      isAuth: false,
    }
  }

  return {
    data: <Session | null>data,
    status,
    isAuth,
  }
}
